import React from 'react'

export default function Animation() {
    return (
      <>
        <div className='shape1'>
          <img src='/imgs/animation/shape1.png' alt='TODAYFILINGS' />
        </div>
        <div className='shape3'>
          <img src='/imgs/animation/shape3.png' alt='TODAYFILINGS' />
        </div>
        <div className='shape6 rotateme'>
          <img src='/imgs/animation/shape4.png' alt='TODAYFILINGS' />
        </div>
        <div className='shape8 rotateme'>
          <img src='/imgs/animation/shape2.png' alt='TODAYFILINGS' />
        </div>
      </>
    );
}
