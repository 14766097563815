import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, EffectFade } from 'swiper'

export default function ImgSlider({ ImagesArray }) {
    return (
      <div className='clients-slider swiper '>
        <Swiper
          modules={[Autoplay, EffectFade]}
          effect
          speed={100}
          slidesPerView={3}
          loop={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 40,
            },

            1200: {
              slidesPerView: 3,
            },
          }}
        >
          <div className='swiper-wrapper align-items-center mt-5'>
            {ImagesArray.map((image, index) => (
              <SwiperSlide key={index}>
                <div className='swiper-slide'>
                  {' '}
                  <img src={image} alt='TODAYFILINGS' className='img-fluid' />
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
      </div>
    );
}